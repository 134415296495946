import React from 'react';

import Layout from '../components/layout';
import { useGlobalState } from '../global/global';
import Button from '../components/button';

import '../styles/contact.css';
import { navigate } from 'gatsby';

const Contact = props => {
    const [state, dispatch] = useGlobalState();

    const setImageSrc = (name) => {
        if (state.lightThemeState) {
            return '/images/light/' + name;
        }

        if (!state.lightThemeState) {
            return '/images/dark/' + name;
        }
    }

    return (
        <Layout location={props.location}>
            <div className='contact-page'>
                <div className='contact-page__left'>
                    <h1 className='contact-page__left__title'>Get in touch <br /> with us</h1>
                    <p className='contact-page__left__detail'>FOMO Works - Grenseveien 21, 4313 Sandnes, Norway </p>
                    <div className='contact-page__left__contact__container'>
                        <img className='contact-page__left__icon' src={setImageSrc('phone.svg')} alt='phone icon' />
                        <a className='contact-page__left__link' href="tel:+47 900 37 036">+47 900 37 036</a>
                    </div>
                    <div className='contact-page__left__contact__container'>
                        <img className='contact-page__left__icon' src={setImageSrc('mail.svg')} alt='email icon' />
                        <a className='contact-page__left__link' href="mailto:info@dataunitor.com">info@dataunitor.com</a>
                    </div>
                    
                </div>
                <iframe className='contact-page__map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2061.5539430008016!2d5.7160814519820615!3d58.888598281416094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x463a357ed7a374bb%3A0x9909308ce9aa909f!2sFOMO!5e0!3m2!1sen!2sno!4v1588095705048!5m2!1sen!2sno"></iframe>
                {/* <div className='contact-page__right'>
                    <div className='contact-page__right__field'>
                        <h5 className='contact-page__right__field__label'>NAME</h5>
                        <textarea className='contact-page__right__field__input'></textarea>
                    </div>

                    <div className='contact-page__right__field'>
                        <h5 className='contact-page__right__field__label'>PHONE</h5>
                        <textarea className='contact-page__right__field__input'></textarea>
                    </div>

                    <div className='contact-page__right__field'>
                        <h5 className='contact-page__right__field__label'>E-MAIL <span className='contact-page__required'>*</span></h5>
                        <textarea className='contact-page__right__field__input'></textarea>
                    </div>

                    <div className='contact-page__right__field'>
                        <h5 className='contact-page__right__field__label'>MESSAGE</h5>
                        <textarea className='contact-page__right__field__input'></textarea>
                    </div>

                    <div className='contact-page__right__field contact-page__right__field--wrap-reverse'>
                        <h5 className='contact-page__right__field__label'><span className='contact-page__required'>*</span> Required field</h5>
                        <div className='contact-page__right__field__button'>
                            <Button backgroundColor='var(--product-button-background-color)' color='var(--product-button-color)' label='SEND MESSAGE'></Button>
                        </div>
                    </div>
                    <p className='contact-page__right__text'>I understand that Dataunitor AS use this form to collect my name, e-mail and phone number to get in touch with me. For more information, please refer to our <u onClick={() => navigate('/privacy-statement')}>Privacy Statement</u></p>
                </div> */}
            </div>
        </Layout>
    );
}

export default Contact;